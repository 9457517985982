<template>
  <Container :title="$t('depositFunds.pageTitle')">
    <TransferFunds/>
  </Container>
</template>

<script>
import TransferFunds from "../components/TransferFunds"
import Vue from 'vue'

export default Vue.extend({
  name: "DepositFunds",
  components: {
    TransferFunds,
  },
})
</script>
