<template>
  <div>
    <div v-if="presentationMode === 'both'">
      <v-row>
        <v-col sm="6" cols="12">
          <v-card outlined>
            <h2 class="text-uppercase font-weight-medium primary--text d-flex align-center mt-6 mb-0 justify-center">
              <span v-text="$t('deposit.directTransfer')"/>
            </h2>
            <h4 class="font-weight-medium grey--text d-flex align-center mb-12 mt-2 justify-center">
              <span v-text="'('+$t('deposit.hintDirect')+')'"/>
            </h4>
            <div class="px-md-5">
              <bank-details/>
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" cols="12">
          <v-card outlined>
            <h2 class="text-uppercase font-weight-medium primary--text d-flex align-center mt-6 mb-0 justify-center">
              <span v-text="$t('stripe.button')"/>
            </h2>
            <h4 class="font-weight-medium grey--text d-flex align-center mb-12 mt-2 justify-center">
              <span v-text="'('+$t('deposit.hintStripe')+')'"/>
            </h4>
            <div class="px-md-5 mb-5">
              <Stripe @next="next"/>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="presentationMode === 'bank_details_only'">
      <v-row>
        <v-col sm="12" cols="12">
          <v-card outlined>
            <h2 class="text-uppercase font-weight-medium primary--text d-flex align-center mb-12 mt-6 justify-center">
              <span v-text="$t('deposit.directTransfer')"/>
            </h2>
            <div class="px-md-5">
              <bank-details/>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="presentationMode === 'unverified'">
      <v-container>
        <v-row>
          <v-col class="d-flex justify-center">
            <div class="text-h5 my-2" v-text="$t('errors.youNeedToVerifyYourIdentityToDepositAndInvest')"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import BankDetails from "../components/BankDetails"
import Stripe from "../components/Stripe"
import Vue from 'vue'
import {VUE_APP_STRIPE_PUBLISHABLE_KEY} from '../../../config'

export default Vue.extend({
  name: "TransferFunds",
  components: {
    BankDetails,
    Stripe,
  },
  data() {
    return {
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
    }
  },
  computed: {
    user() {
      return this.$auth.user()
    },
    investmentAccount() {
      return this.$store.state.account
    },
    pageHint() {
      if (this.$route.name === 'Onboarding') {
        return this.investmentAccount.type !== 'company' ?
          this.$t('stripe.onboardingHintForPrivateAccount') :
          this.$t('stripe.onboardingHintForCompany')
      } else {
        return this.investmentAccount.type !== 'company' ?
          this.$t('stripe.depositFundsHintForPrivateAccount') :
          this.$t('stripe.depositFundsHintForCompany')
      }
    },
    presentationMode() {
      // TransferFunds is used in the onboarding as well as deposit page.
      // But we cannot let users deposit until they actually exist in DB!
      if (!this.user || this.user.verification != 'approved' || !this.user.is_18_years_old || this.investmentAccount.is_frozen) { return 'unverified' }

      // If stripe is unavailable, don't show it
      // if (!VUE_APP_STRIPE_PUBLISHABLE_KEY) { return 'bank_details_only' }  # disable Stripe

      return 'bank_details_only'
    },
  },
  methods: {
    next() {
      this.$emit("next", "true")
    },
  },
})
</script>
