<template>
  <div class="bank-details">
    <div class="text-container">
      <p v-text="$t('depositFunds.ifYouUsePaymentServiceProvider')"/>
      <p v-text="$t('depositFunds.sepaArriveNextDay')"/>
      <p v-text="$t('depositFunds.withdrawFundsOnlyToEuBankAccount')"/>
    </div>
    <br>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.beneficiary') }}</v-col>
      <v-col cols="6">Monestro P2P OÜ</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.IBAN') }}</v-col>
      <v-col cols="6">EE244204278606401912</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.beneficiaryBank') }}</v-col>
      <v-col cols="6">Coop Pank AS</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.SWIFT') }}</v-col>
      <v-col cols="6">EKRDEE22</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.description') }}</v-col>
      <v-col cols="6">{{ account.reference_number }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6">{{ $t('bankDetails.referenceNumber') }}</v-col>
      <v-col cols="6">{{ account.reference_number }}</v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "BankDetails",
  computed: {
    user: function() {
      return this.$auth.user()
    },
    account: function() {
      return this.$store.state.account
    },
  },
})
</script>
