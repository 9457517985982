<template>
  <v-form id="paymentCardForm" ref="paymentCardForm" v-model="formValid" :disabled="!VUE_APP_STRIPE_PUBLISHABLE_KEY" class="compact">
    <v-row>
      <v-col>
        <p>
          <i18n path="stripe.privacyNotice">
            <template #link>
              <a :href="$t('stripe.privacyNoticeUrl')" v-text="$t('stripe.privacyNoticeUrlText')"/>
            </template>
          </i18n>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="activeCards.length" class="mb-6">
      <v-col md="7" cols="12">
        <label class="label" v-text="$t('stripe.chooseCard')"/>
        <v-select
          v-model="current_stripe_card"
          clearable
          :items="activeCards"
          filled
          hide-details
          :item-text="item => `**** **** **** ${item.last4}`"
          item-value="psp_card_id"
          placeholder="**** **** **** XXXX"
          @change="clearRequiredIframe"
        >
          <template #item="{item}">
            <span v-text="`**** **** **** ${item.last4}`"/>
            <v-btn x-small class="ml-2 py-4" text @click="deleteCardDialog = true; cardToDelete = item">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-select>
        <DeletePaymentCard :is-visible="deleteCardDialog" :payment-card="cardToDelete"
                           @cardDeleted="current_stripe_card = null"
                           @closeDeleteCardDialog="closeDeleteCardDialog"
        />
      </v-col>
    </v-row>
    <v-row v-if="!current_stripe_card">
      <v-col>
        <h3 class="title text-uppercase mb-6 mt-2" v-text="$t('stripe.addNewCard')"/>
      </v-col>
    </v-row>
    <v-row v-show="!current_stripe_card" class="compact-p mt-0">
      <v-col md="6" cols="12" class="mb-6">
        <label class="label" v-text="$t('stripe.cardNumber')"/>
        <div :class="cardNumberRequired ? 'field-error' : ''">
          <div id="card-number" ref="cardNumber" :class="theme"/>
        </div>
        <div v-show="cardNumberRequired" class="custom-error v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <label class="label" v-text="$t('stripe.cardExpiry')"/>
        <div :class="cardExpiryRequired ? 'field-error' : ''">
          <div id="card-expiry" ref="cardExpiry" :class="theme"/>
        </div>
        <div v-show="cardExpiryRequired" class="custom-error v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <label class="label" v-text="$t('stripe.cardCVC')"/>
        <div :class="cardExpiryRequired ? 'field-error' : ''">
          <div id="card-cvc" ref="cardCvc" :class="theme"/>
        </div>
        <div v-show="cardCvcRequired" class="custom-error v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message" v-text="$t('errors.fieldRequired')"/>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!current_stripe_card" md="6" cols="12">
        <label class="label" v-text="$t('stripe.cardHolder')"/>
        <v-text-field
          id="stripe_card_holder"
          v-model="cardHolder"
          filled
          :rules="[() => !!cardHolder || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardHolderPlaceholder')"
        />
      </v-col>
      <v-col md="6" cols="12">
        <label class="label" v-text="$t('stripe.amount')"/>
        <v-text-field
          id="stripe_amount"
          v-model.number="amount"
          single-line
          type="number"
          filled
          prefix="€"
          :rules="[
            amount => !!amount || $t('errors.fieldRequired'),
            amount => amount >= 50 && amount <= 1000 || $t('stripe.amountLimit'),
          ]"
          :placeholder="$t('stripe.amountPlaceholder')"
        />
      </v-col>
    </v-row>
    <v-row v-if="!current_stripe_card">
      <v-col md="6" cols="12">
        <label class="label" v-text="$t('stripe.cardCountry')"/>
        <BaseMultiselect
          id="stripe_card_country"
          v-model="cardCountry"
          filled
          :items="countries.country"
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :loading="loadingCountries"
          :multiple="false"
          multi-select-data-type="country"
          required
          :placeholder="$t('stripe.cardCountryPlaceholder')"
        >
          <template #selection="{ item }">
            <span v-text="$t(`countries.${item}`)"/>
          </template>
          <template #item="{ item }">
            <BaseCountry :country="item" no-tooltip/>
            <span class="pl-4" v-text="$t(`countries.${item}`)"/>
          </template>
        </BaseMultiselect>
      </v-col>
      <v-col md="6" cols="12">
        <label class="label" v-text="$t('stripe.cardCity')"/>
        <v-text-field
          id="stripe_card_city"
          v-model="cardCity"
          filled
          :rules="[() => !!cardCity || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardCityPlaceholder')"
        />
      </v-col>
    </v-row>
    <v-row v-if="!current_stripe_card">
      <v-col md="12" cols="12">
        <label class="label" v-text="$t('stripe.cardState')"/>
        <v-text-field
          id="stripe_card_state"
          v-model="cardState"
          filled
          :rules="[() => !!cardState || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardStatePlaceholder')"
        />
      </v-col>
      <v-col md="12" cols="12">
        <label class="label" v-text="$t('stripe.cardPostalCode')"/>
        <v-text-field
          id="stripe_card_postal_code"
          v-model="cardPostalCode"
          filled
          :rules="[() => !!cardPostalCode || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardPostalCodePlaceholder')"
        />
      </v-col>
      <v-col md="12" cols="12">
        <label class="label" v-text="$t('stripe.cardLine1')"/>
        <v-text-field
          id="stripe_card_line_1"
          v-model="cardLine1"
          filled
          :rules="[() => !!cardLine1 || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardLine1Placeholder')"
        />
      </v-col>
      <v-col md="12" cols="12">
        <label class="label" v-text="$t('stripe.cardLine2')"/>
        <v-text-field
          id="stripe_card_line_2"
          v-model="cardLine2"
          filled
          :rules="[() => !!cardLine2 || $t('errors.fieldRequired'),]"
          :placeholder="$t('stripe.cardLine2Placeholder')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          tile
          large
          elevation="0"
          :loading="loading"
          color="primary"
          :disabled="!VUE_APP_STRIPE_PUBLISHABLE_KEY"
          @click="createPayment"
          v-text="$t('stripe.pay')"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import store from "../../../store"
import {VUE_APP_STRIPE_PUBLISHABLE_KEY} from '../../../config'
import DeletePaymentCard from "../../../base/components/DeletePaymentCard"

export default Vue.extend({
  name: 'Stripe',
  components: { DeletePaymentCard },
  data() {
    return {
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardHolder: '',
      cardCountry: '',
      cardCity: '',
      cardLine1: '',
      cardLine2: '',
      cardState: '',
      cardPostalCode: '',
      loading: false,
      amount: 50,
      current_stripe_card: null,
      formValid: false,
      cardNumberRequired: false,
      cardExpiryRequired: false,
      cardCvcRequired: false,
      loadingCountries: true,
      countries: {},
      deleteCardDialog: false,
      cardToDelete: null,
    }
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements({
        locale: this.$i18n.locale,
      })
    },
    currentAccount: function() {
      return this.$store.state.account
    },
    activeCards() {
      return this.currentAccount.payment_cards.filter(card => card.is_active === true)
    },
    theme() {
      return this.$vuetify.theme.dark ? 'theme--dark' : ''
    },
  },
  watch: {
    currentAccount: {
      handler(old, current) {
        if (old.id !== current.id) {
          this.current_stripe_card = null
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.stripeInit()
    })
    this.fetchCountries()
  },
  beforeDestroy() {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  updated() {
    this.$nextTick(() => {
      this.cardNumber.mount('#card-number')
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc.mount('#card-cvc')
    })
  },
  methods: {
    stripeInit() {
      const style = {
        base: {
          color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
          fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          fontWeight: 400,
          textRendering: 'optimizeLegibility',
          '::placeholder': {
            color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)',
            fontWeight: 400,
            fontSize: '16px',
          },
        },
        invalid: {
          color: '#ff5252',
          iconColor: '#ff5252',
        },
      }

      this.cardNumber = this.stripeElements.create('cardNumber', { style })
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc')

    },
    fetchCountries() {
      this.loadingCountries = true
      this.axios
        .get(`/auth/registration_hints/`)
        .then((response) => {
          this.loadingCountries = false
          this.countries = response.data
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingCountries = false
        })
    },
    closeDeleteCardDialog() {
      this.deleteCardDialog = false
    },
    clearForm() {
      if (this.cardNumber) {
        this.cardNumber.clear()
      }
      if (this.cardExpiry) {
        this.cardExpiry.clear()
      }
      if (this.cardCvc) {
        this.cardCvc.clear()
      }

      this.$refs.paymentCardForm.reset()
      this.$refs.paymentCardForm.resetValidation()
      this.clearRequiredIframe()
      this.amount = 50

    },
    clearRequiredIframe() {
      this.cardNumberRequired = false
      this.cardExpiryRequired = false
      this.cardCvcRequired = false
    },
    async createPayment() {
      this.loading = true
      this.clearRequiredIframe()

      const cardNumberEmpty = this.$refs.cardNumber.classList.contains('StripeElement--empty')
      const cardExpiryEmpty = this.$refs.cardExpiry.classList.contains('StripeElement--empty')
      const cardCvcEmpty = this.$refs.cardCvc.classList.contains('StripeElement--empty')

      if (this.current_stripe_card) {
        this.$refs.paymentCardForm.validate()
        if (!this.formValid) {
          this.loading = false
          return
        }
        this.$http
          .post(`/payments/`, {
            "payment_method": this.current_stripe_card,
            "investment_account_id": this.currentAccount.id,
            "payment": {
              "amount": parseFloat(this.amount),
              "currency": 'EUR',
            },
          })
          .then((data) => {
            this.$stripe
              .confirmCardPayment(data.data.client_secret)
              .then((result) => {
                this.loading = false
                if (result.error) {
                  this.$store.commit('pushMessage', {
                    icon: 'mdi-alert',
                    text: result.error.message,
                  })
                } else {
                  this.$store.commit('pushMessage', {
                    icon: 'mdi-robot-happy',
                    text: this.$t('stripe.paymentSuccessful'),
                  })
                  this.$auth.fetch()
                  this.clearForm()
                  this.$emit("next")
                }
              })
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        if (cardNumberEmpty) {
          this.cardNumberRequired = true
        }

        if (cardExpiryEmpty) {
          this.cardExpiryRequired = true
        }

        if (cardCvcEmpty) {
          this.cardCvcRequired = true
        }
        this.$refs.paymentCardForm.validate()

        if (!this.formValid || cardNumberEmpty || cardExpiryEmpty || cardCvcEmpty) {
          this.loading = false
          return
        }

        const {paymentMethod, error} = await this.$stripe.createPaymentMethod({
          type: 'card',
          card: this.cardNumber,
          billing_details: {
            name: this.cardHolder,
            address: {
              city: this.cardCity,
              country: this.cardCountry,
              line1: this.cardLine1,
              line2: this.cardLine2,
              postal_code: this.cardPostalCode,
              state: this.cardState,
            },
          },
        })

        if (error) {
          this.loading = false
          store.commit('pushMessage', {
            icon: 'mdi-alert',
            text: error.message,
          })
          return null
        }

        this.$http
          .post(`/payments/`, {
            "payment_method": paymentMethod.id,
            "investment_account_id": this.currentAccount.id,
            "payment": {
              "amount": parseFloat(this.amount),
              "currency": 'EUR',
            },
          })
          .then((data) => {
            this.$stripe
              .confirmCardPayment(data.data.client_secret)
              .then((result) => {
                this.loading = false
                if (result.error) {
                  this.$store.commit('pushMessage', {
                    icon: 'mdi-alert',
                    text: result.error.message,
                  })
                } else {
                  this.$store.commit('pushMessage', {
                    icon: 'mdi-robot-happy',
                    text:  this.$t('stripe.paymentSuccessful'),
                  })
                  this.$auth.fetch()
                  this.clearForm()
                  this.$emit("next")
                }
              })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
  },
})
</script>

<style scoped>

#card-cvc,
#card-expiry,
#card-number{
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background: rgba(0, 0, 0, 0.06);
  padding: 0 12px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  margin-bottom: 8px;
}
#card-cvc.theme--dark,
#card-expiry.theme--dark,
#card-number.theme--dark{
  background: rgba(255, 255, 255, 0.08);
}

.field-error #card-number,
.field-error #card-expiry,
.field-error #card-cvc{
  border-bottom: 1px solid #ff5252;
}

.custom-error{
  padding: 0 12px;
  margin-bottom: 8px;
}
#card-cvc:hover,
#card-expiry:hover,
#card-number:hover{
  background:rgba(0, 0, 0, 0.12)
}
#card-cvc.theme--dark:hover,
#card-expiry.theme--dark:hover,
#card-number.theme--dark:hover{
  background: rgba(255, 255, 255, 0.16);
}

.label{
  font-size: 12px;
}

.compact > .row > div{
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

</style>
